export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGIN_FRESH = "LOGIN_FRESH"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
export const EMAIL_SUCCESS = "EMAIL_SUCCESS"
export const EMAIL_FAILED = "EMAIL_FAILED"

export const NOTIFICATION_LIST = "NOTIFICATION_LIST"
export const READ_NOTIFICATION = "READ_NOTIFICATION"
export const READ_NOTIFICATION_FRESH = "READ_NOTIFICATION_FRESH"
export const READ_ALL_NOTIFICATION = "READ_ALL_NOTIFICATION"
export const READ_ALL_NOTIFICATION_FRESH = "READ_ALL_NOTIFICATION_FRESH"
export const ALL_NOTIFICATION = "ALL_NOTIFICATION"
export const UNREAD_NOTIFICATION = "UNREAD_NOTIFICATION"

