export const ROLE_ADD = "ROLE_ADD";
export const ROLE_ASSIGN="ROLE_ASSIGN";
export const MODULE_GET_SUCCESS = "MODULE_GET_SUCCESS";
export const MODULE_GET_FAILED = "MODULE_GET_FAILED";
export const MODULE_EDIT_SUCCESS = "MODULE_EDIT_SUCCESS";
export const MODULE_EDIT_FAILED = "MODULE_EDIT_FAILED";
export const ROLE_LIST = "ROLE_LIST";
export const ROLE_DELETE = "ROLE_DELETE";
export const ROLE_ADD_FRESH = "ROLE_ADD_FRESH";
export const ROLE_DELETE_FRESH="ROLE_DELETE_FRESH";
export const ROLE_ASSIGN_FRESH="ROLE_ASSIGN_FRESH";
export const MODULE_EDIT_FRESH = "MODULE_EDIT_FRESH";
export const MODULE_LIST_FRESH = "MODULE_LIST_FRESH";