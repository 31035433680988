export const MODULE_ROUTE_ADD = "MODULE_ROUTE_ADD";
export const MODULE_GET = "MODULE_GET";
export const MODULE_DELETE = "MODULE_DELETE";
export const MODULE_EDIT_SUCCESS = "MODULE_EDIT_SUCCESS";
export const MODULE_EDIT_FAILED = "MODULE_EDIT_FAILED";
export const MODULE_ROUTE_LIST = "MODULE_ROUTE_LIST";
export const MODULE_ROUTE_DELETE = "MODULE_ROUTE_DELETE";
export const MODULE_ROUTE_ADD_FRESH = "MODULE_ROUTE_ADD_FRESH";
export const MODULE_EDIT_FRESH = "MODULE_EDIT_FRESH";
export const MODULE_LIST_FRESH = "MODULE_LIST_FRESH";
export const MODULE_ROUTE_DELETE_FRESH = "MODULE_ROUTE_DELETE_FRESH";
export const MODULE_ROUTE_LIST_FRESH = "MODULE_ROUTE_LIST_FRESH";