/* TASKS */
export const GET_TASKS = "GET_TASKS"
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS"
export const GET_TASKS_FAIL = "GET_TASKS_FAIL"
export const TASK_ADD = "TASK_ADD"
export const TASK_ADD_FRESH = "TASK_ADD_FRESH"
export const GET_TASK_INFO = "GET_TASK_INFO"
export const GET_TASK_INFO_FRESH = "GET_TASK_INFO_FRESH"
export const EDIT_TASK = "EDIT_TASK"
export const TASK_EDIT_FRESH = "TASK_EDIT_FRESH"
export const EDIT_TASK_STATUS = "EDIT_TASK_STATUS"
export const TASK_LEBEL = "TASK_LEBEL"
export const GET_TASK_LABEL_INFO = "GET_TASK_LABEL_INFO"
export const GET_TASK_LABEL_INFO_FRESH = "GET_TASK_LABEL_INFO_FRESH"
export const TASK_FILE = "TASK_FILE"
export const TASK_DELETE = "TASK_DELETE"
export const TASK_DELETE_FRESH = "TASK_DELETE_FRESH"
export const GET_ALL_TASK = "GET_ALL_TASK"
export const GET_ALL_DUE_TASK = "GET_ALL_DUE_TASK"
export const GET_ALL_DUE_TASK_FRESH = "GET_ALL_DUE_TASK_FRESH"
export const GET_ALL_ACTIVE_TASK = "GET_ALL_ACTIVE_TASK"
export const GET_ALL_ACTIVE_TASK_FRESH = "GET_ALL_ACTIVE_TASK_FRESH"
export const GET_ALL_DUE_LATER_TASK = "GET_ALL_DUE_LATER_TASK"
export const GET_ALL_DUE_LATER_TASK_FRESH = "GET_ALL_DUE_LATER_TASK_FRESH"
export const SWAP_TASK = "SWAP_TASK"
export const SWAP_TASK_FRESH = "SWAP_TASK_FRESH"
export const GET_TASK_ACTIVITY = "GET_TASK_ACTIVITY"
export const GET_ALL_CLOSED_TASK = "GET_ALL_CLOSED_TASK"
export const GET_ALL_CLOSED_TASK_FRESH = "GET_ALL_CLOSED_TASK_FRESH"

