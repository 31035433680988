const transactionsData = JSON.parse(`[
    {
      "id": 316,
      "property_id": 5035,
      "contact_id": 2738,
      "amount": 500,
      "amount_type": "eft",
      "receipt_date": "2023-10-29",
      "ref": null,
      "type": "Tenant Receipt",
      "summary": "Paid to 2024-01-26 (from 2023-12-26)",
      "payment_method": "eft",
      "from": null,
      "created_by": "Jake Wang",
      "updated_by": "Jake Wang",
      "paid_by": "eft",
      "cleared_date": "2023-10-29",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 500,
      "folio_id": 15,
      "folio_type": "Tenant",
      "rent_amount": 500,
      "deposit_amount": null,
      "from_folio_id": 15,
      "from_folio_type": "Tenant",
      "to_folio_id": 470,
      "to_folio_type": "Owner",
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-29T07:17:36.000000Z",
      "updated_at": "2023-10-29T07:17:36.000000Z",
      "reverse_status": null,
      "create_date": "2023-10-29",
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 0,
      "new_type": "Receipt",
      "owner_folio_id": null,
      "tenant_folio_id": 15,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": null,
      "credit_receipt_details_sum_amount": 500,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "receipt_details": [
        {
          "id": 495,
          "receipt_id": 316,
          "allocation": "Rent",
          "description": "Paid to 2024-01-26 (from 2023-12-26)",
          "payment_type": "eft",
          "amount": "500",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": null,
          "tax": null,
          "account_id": null,
          "from_folio_id": 15,
          "from_folio_type": "Tenant",
          "to_folio_id": 470,
          "to_folio_type": "Owner",
          "disbursed": 0,
          "company_id": 1,
          "created_at": "2023-10-29T07:17:36.000000Z",
          "updated_at": "2023-10-29T07:17:36.000000Z",
          "reverse_status": null,
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": null
        }
      ]
    },
    {
      "id": 315,
      "property_id": 5035,
      "contact_id": 2738,
      "amount": 800,
      "amount_type": "eft",
      "receipt_date": "2023-10-29",
      "ref": null,
      "type": "Tenant Receipt",
      "summary": "Paid to 2023-12-26 (from 2023-11-26)",
      "payment_method": "eft",
      "from": null,
      "created_by": "Jake Wang",
      "updated_by": "Jake Wang",
      "paid_by": "eft",
      "cleared_date": "2023-10-29",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 800,
      "folio_id": 15,
      "folio_type": "Tenant",
      "rent_amount": 500,
      "deposit_amount": null,
      "from_folio_id": 15,
      "from_folio_type": "Tenant",
      "to_folio_id": 470,
      "to_folio_type": "Owner",
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-29T06:15:55.000000Z",
      "updated_at": "2023-10-29T06:15:55.000000Z",
      "reverse_status": null,
      "create_date": "2023-10-29",
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 0,
      "new_type": "Receipt",
      "owner_folio_id": null,
      "tenant_folio_id": 15,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": null,
      "credit_receipt_details_sum_amount": 500,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "receipt_details": [
        {
          "id": 493,
          "receipt_id": 315,
          "allocation": "Rent",
          "description": "Paid to 2023-12-26 (from 2023-11-26)",
          "payment_type": "eft",
          "amount": "500",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": null,
          "tax": null,
          "account_id": null,
          "from_folio_id": 15,
          "from_folio_type": "Tenant",
          "to_folio_id": 470,
          "to_folio_type": "Owner",
          "disbursed": 0,
          "company_id": 1,
          "created_at": "2023-10-29T06:15:55.000000Z",
          "updated_at": "2023-10-29T06:15:55.000000Z",
          "reverse_status": null,
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": null
        },
        {
          "id": 494,
          "receipt_id": 315,
          "allocation": "Bond",
          "description": "Part payment of bond for William Street, 1",
          "payment_type": "eft",
          "amount": "300",
          "folio_id": 480,
          "folio_type": "Supplier",
          "type": null,
          "tax": null,
          "account_id": null,
          "from_folio_id": 15,
          "from_folio_type": "Tenant",
          "to_folio_id": 480,
          "to_folio_type": "Supplier",
          "disbursed": 0,
          "company_id": 1,
          "created_at": "2023-10-29T06:15:56.000000Z",
          "updated_at": "2023-10-29T06:15:56.000000Z",
          "reverse_status": null,
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": null,
          "tenant_folio_id": null,
          "supplier_folio_id": 480,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "SUP000495",
          "contact_reference": {
            "id": 495,
            "contact_id": 2729,
            "reference": "MyDay Owner"
          },
          "account": null
        }
      ]
    },
    {
      "id": 314,
      "property_id": 5035,
      "contact_id": null,
      "amount": 5470,
      "amount_type": null,
      "receipt_date": "2023-10-27",
      "ref": null,
      "type": "Withdraw",
      "summary": "Withdrawal by EFT to owner",
      "payment_method": "eft",
      "from": "Owner",
      "created_by": "Jake Wang",
      "updated_by": "",
      "paid_by": null,
      "cleared_date": "2023-10-27",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 0,
      "folio_id": 470,
      "folio_type": "Owner",
      "rent_amount": 0,
      "deposit_amount": 0,
      "from_folio_id": 470,
      "from_folio_type": "Owner",
      "to_folio_id": null,
      "to_folio_type": null,
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-26T18:39:03.000000Z",
      "updated_at": "2023-10-26T18:39:03.000000Z",
      "reverse_status": null,
      "create_date": null,
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 0,
      "new_type": "Withdrawal",
      "owner_folio_id": 470,
      "tenant_folio_id": null,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": 5470,
      "credit_receipt_details_sum_amount": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "receipt_details": [
        {
          "id": 492,
          "receipt_id": 314,
          "allocation": "",
          "description": "Withdrawal by EFT to owner",
          "payment_type": "eft",
          "amount": "5470",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": "Withdraw",
          "tax": 0,
          "account_id": null,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": null,
          "to_folio_type": null,
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:39:03.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": "",
          "invoice_id": null,
          "pay_type": "debit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": null
        }
      ]
    },
    {
      "id": 313,
      "property_id": 5035,
      "contact_id": null,
      "amount": 30,
      "amount_type": null,
      "receipt_date": "2023-10-27",
      "ref": null,
      "type": "Bill",
      "summary": "1",
      "payment_method": "",
      "from": "Owner",
      "created_by": "Jake Wang",
      "updated_by": "",
      "paid_by": null,
      "cleared_date": "2023-10-27",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 0,
      "folio_id": 470,
      "folio_type": "Owner",
      "rent_amount": 0,
      "deposit_amount": 0,
      "from_folio_id": 470,
      "from_folio_type": "Owner",
      "to_folio_id": 480,
      "to_folio_type": "Supplier",
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-26T18:39:03.000000Z",
      "updated_at": "2023-10-26T18:39:03.000000Z",
      "reverse_status": null,
      "create_date": null,
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 1,
      "new_type": "Payment",
      "owner_folio_id": 470,
      "tenant_folio_id": null,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": 30,
      "credit_receipt_details_sum_amount": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "receipt_details": [
        {
          "id": 490,
          "receipt_id": 313,
          "allocation": "Owner Bill",
          "description": "1",
          "payment_type": "",
          "amount": "30",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": "Withdraw",
          "tax": 0,
          "account_id": 1,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": 480,
          "to_folio_type": "Supplier",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:39:03.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": "",
          "invoice_id": null,
          "pay_type": "debit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": {
            "id": 1,
            "account_name": "Rent",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:40:57.000000Z",
            "updated_at": "2023-07-17T07:40:57.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 200,
            "tax": 1,
            "hidden": 1
          }
        },
        {
          "id": 491,
          "receipt_id": 313,
          "allocation": "Supplier Bill",
          "description": "1",
          "payment_type": "",
          "amount": "30",
          "folio_id": 480,
          "folio_type": "Supplier",
          "type": "Deposit",
          "tax": 0,
          "account_id": 1,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": 480,
          "to_folio_type": "Supplier",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:39:03.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": "",
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": null,
          "tenant_folio_id": null,
          "supplier_folio_id": 480,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "SUP000495",
          "contact_reference": {
            "id": 495,
            "contact_id": 2729,
            "reference": "MyDay Owner"
          },
          "account": {
            "id": 1,
            "account_name": "Rent",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:40:57.000000Z",
            "updated_at": "2023-07-17T07:40:57.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 200,
            "tax": 1,
            "hidden": 1
          }
        }
      ]
    },
    {
      "id": 312,
      "property_id": null,
      "contact_id": null,
      "amount": 20,
      "amount_type": null,
      "receipt_date": "2023-10-27",
      "ref": null,
      "type": "Bill",
      "summary": "2",
      "payment_method": "",
      "from": "Owner",
      "created_by": "Jake Wang",
      "updated_by": "",
      "paid_by": null,
      "cleared_date": "2023-10-27",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 0,
      "folio_id": 470,
      "folio_type": "Owner",
      "rent_amount": 0,
      "deposit_amount": 0,
      "from_folio_id": 470,
      "from_folio_type": "Owner",
      "to_folio_id": 480,
      "to_folio_type": "Supplier",
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-26T18:39:03.000000Z",
      "updated_at": "2023-10-26T18:39:03.000000Z",
      "reverse_status": null,
      "create_date": null,
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 1,
      "new_type": "Payment",
      "owner_folio_id": 470,
      "tenant_folio_id": null,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": 20,
      "credit_receipt_details_sum_amount": null,
      "property": null,
      "receipt_details": [
        {
          "id": 488,
          "receipt_id": 312,
          "allocation": "Owner Bill",
          "description": "2",
          "payment_type": "",
          "amount": "20",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": "Withdraw",
          "tax": 1,
          "account_id": 2,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": 480,
          "to_folio_type": "Supplier",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:39:03.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": "",
          "invoice_id": null,
          "pay_type": "debit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": {
            "id": 2,
            "account_name": "Bond Details",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:41:18.000000Z",
            "updated_at": "2023-07-17T07:41:18.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 400,
            "tax": 1,
            "hidden": 1
          }
        },
        {
          "id": 489,
          "receipt_id": 312,
          "allocation": "Supplier Bill",
          "description": "2",
          "payment_type": "",
          "amount": "20",
          "folio_id": 480,
          "folio_type": "Supplier",
          "type": "Deposit",
          "tax": 1,
          "account_id": 2,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": 480,
          "to_folio_type": "Supplier",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:39:03.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": "",
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": null,
          "tenant_folio_id": null,
          "supplier_folio_id": 480,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "SUP000495",
          "contact_reference": {
            "id": 495,
            "contact_id": 2729,
            "reference": "MyDay Owner"
          },
          "account": {
            "id": 2,
            "account_name": "Bond Details",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:41:18.000000Z",
            "updated_at": "2023-07-17T07:41:18.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 400,
            "tax": 1,
            "hidden": 1
          }
        }
      ]
    },
    {
      "id": 311,
      "property_id": null,
      "contact_id": null,
      "amount": 20,
      "amount_type": null,
      "receipt_date": "2023-10-27",
      "ref": null,
      "type": "Bill",
      "summary": "2",
      "payment_method": "",
      "from": "Owner",
      "created_by": "Jake Wang",
      "updated_by": "",
      "paid_by": null,
      "cleared_date": "2023-10-27",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 0,
      "folio_id": 470,
      "folio_type": "Owner",
      "rent_amount": 0,
      "deposit_amount": 0,
      "from_folio_id": 470,
      "from_folio_type": "Owner",
      "to_folio_id": 480,
      "to_folio_type": "Supplier",
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-26T18:39:03.000000Z",
      "updated_at": "2023-10-26T18:39:03.000000Z",
      "reverse_status": null,
      "create_date": null,
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 1,
      "new_type": "Payment",
      "owner_folio_id": 470,
      "tenant_folio_id": null,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": 20,
      "credit_receipt_details_sum_amount": null,
      "property": null,
      "receipt_details": [
        {
          "id": 486,
          "receipt_id": 311,
          "allocation": "Owner Bill",
          "description": "2",
          "payment_type": "",
          "amount": "20",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": "Withdraw",
          "tax": 1,
          "account_id": 2,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": 480,
          "to_folio_type": "Supplier",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:39:03.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": "",
          "invoice_id": null,
          "pay_type": "debit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": {
            "id": 2,
            "account_name": "Bond Details",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:41:18.000000Z",
            "updated_at": "2023-07-17T07:41:18.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 400,
            "tax": 1,
            "hidden": 1
          }
        },
        {
          "id": 487,
          "receipt_id": 311,
          "allocation": "Supplier Bill",
          "description": "2",
          "payment_type": "",
          "amount": "20",
          "folio_id": 480,
          "folio_type": "Supplier",
          "type": "Deposit",
          "tax": 1,
          "account_id": 2,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": 480,
          "to_folio_type": "Supplier",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:39:03.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": "",
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": null,
          "tenant_folio_id": null,
          "supplier_folio_id": 480,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "SUP000495",
          "contact_reference": {
            "id": 495,
            "contact_id": 2729,
            "reference": "MyDay Owner"
          },
          "account": {
            "id": 2,
            "account_name": "Bond Details",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:41:18.000000Z",
            "updated_at": "2023-07-17T07:41:18.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 400,
            "tax": 1,
            "hidden": 1
          }
        }
      ]
    },
    {
      "id": 309,
      "property_id": null,
      "contact_id": 2735,
      "amount": 60,
      "amount_type": null,
      "receipt_date": "2023-10-27",
      "ref": null,
      "type": "Journal",
      "summary": "bill",
      "payment_method": null,
      "from": null,
      "created_by": "Jake Wang",
      "updated_by": null,
      "paid_by": null,
      "cleared_date": "2023-10-27",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 0,
      "folio_id": 470,
      "folio_type": "Owner",
      "rent_amount": 0,
      "deposit_amount": 0,
      "from_folio_id": 470,
      "from_folio_type": "Owner",
      "to_folio_id": 15,
      "to_folio_type": "Tenant",
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-26T18:32:06.000000Z",
      "updated_at": "2023-10-26T18:39:03.000000Z",
      "reverse_status": null,
      "create_date": null,
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 1,
      "new_type": "Journal",
      "owner_folio_id": null,
      "tenant_folio_id": null,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": 60,
      "credit_receipt_details_sum_amount": null,
      "property": null,
      "receipt_details": [
        {
          "id": 482,
          "receipt_id": 309,
          "allocation": "Journal",
          "description": "bill",
          "payment_type": null,
          "amount": "60",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": "Withdraw",
          "tax": 0,
          "account_id": 1,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": 15,
          "to_folio_type": "Tenant",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:32:06.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": null,
          "invoice_id": null,
          "pay_type": "debit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": {
            "id": 1,
            "account_name": "Rent",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:40:57.000000Z",
            "updated_at": "2023-07-17T07:40:57.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 200,
            "tax": 1,
            "hidden": 1
          }
        },
        {
          "id": 483,
          "receipt_id": 309,
          "allocation": "Journal",
          "description": "bill",
          "payment_type": null,
          "amount": "60",
          "folio_id": 15,
          "folio_type": "Tenant",
          "type": "Deposit",
          "tax": 0,
          "account_id": 1,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": 15,
          "to_folio_type": "Tenant",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:32:06.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": null,
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": null,
          "tenant_folio_id": 15,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "TEN000435",
          "contact_reference": {
            "id": 435,
            "contact_id": 2738,
            "reference": "William Street Tenant"
          },
          "account": {
            "id": 1,
            "account_name": "Rent",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:40:57.000000Z",
            "updated_at": "2023-07-17T07:40:57.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 200,
            "tax": 1,
            "hidden": 1
          }
        }
      ]
    },
    {
      "id": 308,
      "property_id": null,
      "contact_id": 2735,
      "amount": 100,
      "amount_type": null,
      "receipt_date": "2023-10-27",
      "ref": null,
      "type": "Folio Withdraw",
      "summary": "Withdrawal to William Street Owner",
      "payment_method": null,
      "from": null,
      "created_by": "Jake Wang",
      "updated_by": null,
      "paid_by": null,
      "cleared_date": "2023-10-27",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 0,
      "folio_id": 470,
      "folio_type": "Owner",
      "rent_amount": 0,
      "deposit_amount": 0,
      "from_folio_id": 470,
      "from_folio_type": "Owner",
      "to_folio_id": null,
      "to_folio_type": null,
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-26T18:31:31.000000Z",
      "updated_at": "2023-10-26T18:39:03.000000Z",
      "reverse_status": null,
      "create_date": null,
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 1,
      "new_type": "Withdrawal",
      "owner_folio_id": 470,
      "tenant_folio_id": null,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": 100,
      "credit_receipt_details_sum_amount": null,
      "property": null,
      "receipt_details": [
        {
          "id": 481,
          "receipt_id": 308,
          "allocation": "Folio Withdraw",
          "description": "Withdrawal to William Street Owner",
          "payment_type": null,
          "amount": "100",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": "Withdraw",
          "tax": 0,
          "account_id": 1,
          "from_folio_id": 470,
          "from_folio_type": "Owner",
          "to_folio_id": null,
          "to_folio_type": null,
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:31:31.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": null,
          "invoice_id": null,
          "pay_type": "debit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": {
            "id": 1,
            "account_name": "Rent",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-07-17T07:40:57.000000Z",
            "updated_at": "2023-07-17T07:40:57.000000Z",
            "type": "Income",
            "description": null,
            "account_number": 200,
            "tax": 1,
            "hidden": 1
          }
        }
      ]
    },
    {
      "id": 307,
      "property_id": null,
      "contact_id": 2735,
      "amount": 5000,
      "amount_type": null,
      "receipt_date": "2023-10-27",
      "ref": null,
      "type": "Folio Receipt",
      "summary": "a",
      "payment_method": "eft",
      "from": "a",
      "created_by": null,
      "updated_by": null,
      "paid_by": null,
      "cleared_date": "2023-10-27",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 0,
      "folio_id": 470,
      "folio_type": "Owner",
      "rent_amount": 0,
      "deposit_amount": 0,
      "from_folio_id": 470,
      "from_folio_type": "Owner",
      "to_folio_id": null,
      "to_folio_type": null,
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-26T18:30:38.000000Z",
      "updated_at": "2023-10-26T18:39:03.000000Z",
      "reverse_status": null,
      "create_date": null,
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 1,
      "new_type": "Receipt",
      "owner_folio_id": 470,
      "tenant_folio_id": null,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": null,
      "credit_receipt_details_sum_amount": 5000,
      "property": null,
      "receipt_details": [
        {
          "id": 480,
          "receipt_id": 307,
          "allocation": "Folio Receipt",
          "description": "a",
          "payment_type": "eft",
          "amount": "5000",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": "Deposit",
          "tax": null,
          "account_id": 11,
          "from_folio_id": null,
          "from_folio_type": null,
          "to_folio_id": 470,
          "to_folio_type": "Owner",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:30:38.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": null,
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": {
            "id": 11,
            "account_name": "Bond Claim - General Repairs Maintenance",
            "company_id": 1,
            "account_type": null,
            "created_at": "2023-08-09T05:18:45.000000Z",
            "updated_at": "2023-08-09T05:18:45.000000Z",
            "type": "Income",
            "description": "",
            "account_number": 300,
            "tax": 1,
            "hidden": 0
          }
        }
      ]
    },
    {
      "id": 306,
      "property_id": 5035,
      "contact_id": 2738,
      "amount": 800,
      "amount_type": "eft",
      "receipt_date": "2023-10-27",
      "ref": null,
      "type": "Tenant Receipt",
      "summary": "Paid to 1 months with part payment of $300 (from 2023-10-26)",
      "payment_method": "eft",
      "from": null,
      "created_by": "Jake Wang",
      "updated_by": "Jake Wang",
      "paid_by": "eft",
      "cleared_date": "2023-10-27",
      "cheque_drawer": null,
      "cheque_bank": null,
      "cheque_branch": null,
      "cheque_amount": 800,
      "folio_id": 15,
      "folio_type": "Tenant",
      "rent_amount": 800,
      "deposit_amount": null,
      "from_folio_id": 15,
      "from_folio_type": "Tenant",
      "to_folio_id": 470,
      "to_folio_type": "Owner",
      "company_id": 1,
      "status": "Cleared",
      "created_at": "2023-10-26T18:29:22.000000Z",
      "updated_at": "2023-10-26T18:39:03.000000Z",
      "reverse_status": null,
      "create_date": "2023-10-27",
      "note": null,
      "reversed_date": null,
      "reversed": 0,
      "disbursed": 1,
      "new_type": "Receipt",
      "owner_folio_id": null,
      "tenant_folio_id": 15,
      "supplier_folio_id": null,
      "totalTaxAmount": 0,
      "doc_path": null,
      "seller_folio_id": null,
      "debit_receipt_details_sum_amount": null,
      "credit_receipt_details_sum_amount": 800,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "receipt_details": [
        {
          "id": 479,
          "receipt_id": 306,
          "allocation": "Rent",
          "description": "Paid to 1 months with part payment of $300 (from 2023-10-26)",
          "payment_type": "eft",
          "amount": "800",
          "folio_id": 470,
          "folio_type": "Owner",
          "type": null,
          "tax": null,
          "account_id": null,
          "from_folio_id": 15,
          "from_folio_type": "Tenant",
          "to_folio_id": 470,
          "to_folio_type": "Owner",
          "disbursed": 1,
          "company_id": 1,
          "created_at": "2023-10-26T18:29:22.000000Z",
          "updated_at": "2023-10-26T18:39:03.000000Z",
          "reverse_status": null,
          "invoice_id": null,
          "pay_type": "credit",
          "owner_folio_id": 470,
          "tenant_folio_id": null,
          "supplier_folio_id": null,
          "taxAmount": 0,
          "seller_folio_id": null,
          "folioCode": "OWN000470",
          "contact_reference": {
            "id": 474,
            "contact_id": 2735,
            "reference": "Kane Williamson, & William Smith"
          },
          "account": null
        }
      ]
    }
  ]`)

export const transactionsMainData = { data: transactionsData }

const bill = JSON.parse(`[
    {
      "id": 185,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 1,
      "invoice_ref": "SUPPLIER BILL",
      "property_id": 5035,
      "amount": 200,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:25:42.000000Z",
      "updated_at": "2023-10-29T07:25:44.000000Z",
      "priority": "",
      "details": "Rent (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291325bill-185.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 1,
        "account_name": "Rent",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:40:57.000000Z",
        "updated_at": "2023-07-17T07:40:57.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 200,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 184,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 1,
      "invoice_ref": "asd",
      "property_id": 5035,
      "amount": 100,
      "file": null,
      "include_tax": 0,
      "maintenance_id": 1403,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:25:42.000000Z",
      "updated_at": "2023-10-29T07:25:45.000000Z",
      "priority": "High",
      "details": "bill",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291325bill-184.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": {
        "id": 1403,
        "property_id": 5035,
        "reported_by": "Tenant",
        "access": "Tenant",
        "due_by": "2023-10-29",
        "manager_id": 2,
        "tenant_id": "435",
        "summary": "no summary",
        "description": null,
        "work_order_notes": null,
        "status": "Closed",
        "completed": "2023-10-29",
        "company_id": 1,
        "created_at": "2023-10-29T07:24:12.000000Z",
        "updated_at": "2023-10-29T07:25:42.000000Z",
        "property_reference": "William Street, 1",
        "manager_first_name": "Jake Wang",
        "manager_last_name": "Jake Wang",
        "maintenance_by_supplier_id": {},
        "supplier_name": null
      },
      "bill": {
        "id": 1,
        "account_name": "Rent",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:40:57.000000Z",
        "updated_at": "2023-07-17T07:40:57.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 200,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 183,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 2,
      "invoice_ref": "AGREEMENT DATE",
      "property_id": 5035,
      "amount": 5,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:22:00.000000Z",
      "updated_at": "2023-10-29T07:22:02.000000Z",
      "priority": "",
      "details": "Bond Details (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291322bill-183.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 2,
        "account_name": "Bond Details",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:41:18.000000Z",
        "updated_at": "2023-07-17T07:41:18.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 400,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 182,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 4,
      "invoice_ref": "OWNER INVOICE",
      "property_id": 5035,
      "amount": 5,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:20:05.000000Z",
      "updated_at": "2023-10-29T07:20:06.000000Z",
      "priority": "",
      "details": "a (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291320bill-182.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 4,
        "account_name": "a",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T10:17:29.000000Z",
        "updated_at": "2023-07-17T10:17:29.000000Z",
        "type": "Income",
        "description": "a",
        "account_number": 10,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 181,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 1,
      "invoice_ref": "quetryyyyyyyyyy",
      "property_id": 5035,
      "amount": 25,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:17:35.000000Z",
      "updated_at": "2023-10-29T07:17:36.000000Z",
      "priority": "",
      "details": "Rent (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291317bill-181.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 1,
        "account_name": "Rent",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:40:57.000000Z",
        "updated_at": "2023-07-17T07:40:57.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 200,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 180,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 1,
      "invoice_ref": "Sundry Fee",
      "property_id": 5035,
      "amount": 100,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:17:34.000000Z",
      "updated_at": "2023-10-29T07:17:35.000000Z",
      "priority": "",
      "details": "Rent (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291317bill-180.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 1,
        "account_name": "Rent",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:40:57.000000Z",
        "updated_at": "2023-07-17T07:40:57.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 200,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 179,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 2,
      "invoice_ref": "INSPECTION EXIT",
      "property_id": 5035,
      "amount": 60,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:13:15.000000Z",
      "updated_at": "2023-10-29T07:13:17.000000Z",
      "priority": "",
      "details": "Bond Details (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291313bill-179.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 2,
        "account_name": "Bond Details",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:41:18.000000Z",
        "updated_at": "2023-07-17T07:41:18.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 400,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 178,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 1,
      "invoice_ref": "INSPECTION ENTRY",
      "property_id": 5035,
      "amount": 50,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:11:06.000000Z",
      "updated_at": "2023-10-29T07:11:07.000000Z",
      "priority": "",
      "details": "Rent (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291311bill-178.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 1,
        "account_name": "Rent",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:40:57.000000Z",
        "updated_at": "2023-07-17T07:40:57.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 200,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 177,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 2,
      "invoice_ref": "INSPECTION ROUTINE",
      "property_id": 5035,
      "amount": 120,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T07:04:55.000000Z",
      "updated_at": "2023-10-29T07:04:56.000000Z",
      "priority": "",
      "details": "Bond Details (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291304bill-177.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 2,
        "account_name": "Bond Details",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:41:18.000000Z",
        "updated_at": "2023-07-17T07:41:18.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 400,
        "tax": 1,
        "hidden": 1
      }
    },
    {
      "id": 176,
      "billing_date": "2023-10-29",
      "supplier_contact_id": 495,
      "bill_account_id": 1,
      "invoice_ref": "quetryyyyyyyyyy",
      "property_id": 5035,
      "amount": 25,
      "file": null,
      "include_tax": 1,
      "maintenance_id": null,
      "company_id": 1,
      "uploaded": null,
      "status": "Unpaid",
      "owner_folio_id": 470,
      "supplier_folio_id": 480,
      "created_at": "2023-10-29T06:15:44.000000Z",
      "updated_at": "2023-10-29T06:15:55.000000Z",
      "priority": "",
      "details": "Rent (System Generated)",
      "disbursed": 0,
      "note": null,
      "receipt_id": null,
      "approved": 0,
      "doc_path": "local/Document/202310291215bill-176.pdf",
      "taxAmount": 0,
      "seller_folio_id": null,
      "owner": {
        "id": 474,
        "contact_id": 2735,
        "property_id": 5035,
        "reference": "Kane Williamson, & William Smith",
        "first_name": "Kane",
        "last_name": "Williamson",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "12312312",
        "work_phone": null,
        "home_phone": null,
        "email": "noname2@email.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-11-08T06:33:13.000000Z",
        "status": 1,
        "owner_folio_id": null,
        "owner_folio": null
      },
      "sellers": null,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com",
        "owner_one": {
          "id": 474,
          "contact_id": 2735,
          "property_id": 5035,
          "reference": "Kane Williamson, & William Smith",
          "first_name": "Kane",
          "last_name": "Williamson",
          "salutation": null,
          "company_name": null,
          "mobile_phone": "12312312",
          "work_phone": null,
          "home_phone": null,
          "email": "noname2@email.com",
          "notes": null,
          "company_id": 1,
          "user_id": null,
          "created_at": "2023-10-22T13:26:24.000000Z",
          "updated_at": "2023-11-08T06:33:13.000000Z",
          "status": 1,
          "owner_folio_id": null
        }
      },
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "supplier": {
        "id": 495,
        "contact_id": 2729,
        "reference": "MyDay Owner",
        "first_name": "MyDay",
        "last_name": "Owner",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "01876538813",
        "work_phone": null,
        "home_phone": null,
        "email": "mirajul.hoque@cliqpack.com",
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "abn": null,
        "created_at": "2023-09-18T08:56:36.000000Z",
        "updated_at": "2023-09-18T08:56:36.000000Z"
      },
      "maintenance": null,
      "bill": {
        "id": 1,
        "account_name": "Rent",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:40:57.000000Z",
        "updated_at": "2023-07-17T07:40:57.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 200,
        "tax": 1,
        "hidden": 1
      }
    }
  ]`)


export const billData = { data: bill }

const invoice = JSON.parse(`[
    {
      "id": 42,
      "invoice_billing_date": "2023-10-29",
      "supplier_contact_id": null,
      "chart_of_account_id": 2,
      "details": "aaaa",
      "property_id": 5035,
      "amount": 50,
      "file": null,
      "include_tax": 0,
      "tenant_contact_id": 435,
      "company_id": 1,
      "status": "Unpaid",
      "created_at": "2023-10-29T07:20:06.000000Z",
      "updated_at": "2023-10-29T07:20:08.000000Z",
      "uploaded": null,
      "supplier_folio_id": null,
      "owner_folio_id": 470,
      "tenant_folio_id": 15,
      "receipt_details_id": null,
      "paid": 0,
      "sent": null,
      "type": null,
      "doc_path": "local/Document/202310291320invoice-42.pdf",
      "taxAmount": 0,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com"
      },
      "supplier": null,
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "tenant": {
        "id": 435,
        "reference": "William Street Tenant",
        "contact_id": 2738,
        "property_id": 5035,
        "first_name": "William Street",
        "last_name": "Tenant",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "4444535",
        "work_phone": null,
        "home_phone": null,
        "email": "kevinzhao@cliqpack.com",
        "abn": null,
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-26T18:23:52.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "true"
      },
      "chart_of_account": {
        "id": 2,
        "account_name": "Bond Details",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:41:18.000000Z",
        "updated_at": "2023-07-17T07:41:18.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 400,
        "tax": 1,
        "hidden": 1
      },
      "tenant_folio": {
        "id": 15,
        "tenant_contact_id": 435,
        "property_id": 5035,
        "deposit": 60,
        "money_in": 60,
        "folio_code": "TEN000435",
        "rent_arrers": null
      }
    },
    {
      "id": 41,
      "invoice_billing_date": "2023-10-27",
      "supplier_contact_id": null,
      "chart_of_account_id": 2,
      "details": "aaaa",
      "property_id": 5035,
      "amount": 200,
      "file": "local/Image/fmJS82vSqTt91JtSm0QThtksDFBChg6Ya9X8lSrx.jpg",
      "include_tax": 0,
      "tenant_contact_id": 435,
      "company_id": 1,
      "status": "Unpaid",
      "created_at": "2023-10-26T18:25:18.000000Z",
      "updated_at": "2023-10-26T18:25:23.000000Z",
      "uploaded": null,
      "supplier_folio_id": null,
      "owner_folio_id": 470,
      "tenant_folio_id": 15,
      "receipt_details_id": null,
      "paid": 0,
      "sent": null,
      "type": null,
      "doc_path": "local/Document/202310270025invoice-41.pdf",
      "taxAmount": 0,
      "property": {
        "id": 5035,
        "reference": "William Street, 1",
        "manager_id": 2,
        "location": "-33.8743446,151.2131667",
        "property_type": 1,
        "primary_type": "Residential",
        "description": null,
        "bathroom": null,
        "bedroom": null,
        "car_space": null,
        "floor_area": "m2",
        "floor_size": null,
        "land_area": "m2",
        "land_size": null,
        "key_number": null,
        "strata_manager_id": null,
        "routine_inspections_frequency": null,
        "routine_inspections_frequency_type": "Weekly",
        "first_routine": null,
        "first_routine_frequency_type": "Weekly",
        "routine_inspection_due_date": null,
        "note": null,
        "property_image": null,
        "owner": "Kane Williamson, & William Smith",
        "tenant": "William Street Tenant",
        "company_id": 1,
        "created_at": "2023-10-22T12:34:23.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "Active",
        "youtube_link": null,
        "vr_link": null,
        "subject": null,
        "owner_folio_id": 470,
        "owner_contact_id": 474,
        "manager": "Jake Wang",
        "manager_name": "Jake Wang",
        "tenant_id": 435,
        "owner_id": 474,
        "tenant_contact_id": 2738,
        "last_inspection": [
          {
            "id": 3777,
            "property_id": 5035,
            "inspection_type": "Exit",
            "inspection_date": "2023-10-30",
            "start_time": "13:12:00",
            "end_time": "14:12:00",
            "duration": "60",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:12:46.000000Z",
            "updated_at": "2023-10-29T07:13:17.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          },
          {
            "id": 3776,
            "property_id": 5035,
            "inspection_type": "Entry",
            "inspection_date": "2023-10-29",
            "start_time": "13:10:00",
            "end_time": "13:16:00",
            "duration": "6",
            "summery": "no summary",
            "manager_id": 2,
            "level": "null",
            "status": "complete",
            "company_id": 1,
            "created_at": "2023-10-29T07:10:17.000000Z",
            "updated_at": "2023-10-29T07:11:07.000000Z",
            "inspection_completed": "2023-10-29",
            "master_schedule_id": null,
            "reference": "William Street, 1",
            "location": {
              "lat": "-33.8743446",
              "lng": "151.2131667"
            },
            "first_name": "William Street Tenant",
            "last_name": "Tenant",
            "manager": "Jake Wang",
            "address": {
              "id": 22,
              "property_id": 5035,
              "building_name": null,
              "unit": null,
              "number": "1",
              "street": "William Street",
              "suburb": "Darlinghurst",
              "postcode": "2010",
              "state": "New South Wales",
              "country": "Australia",
              "created_at": "2023-10-22T12:34:23.000000Z",
              "updated_at": "2023-10-22T12:34:23.000000Z"
            },
            "tanent_data": "William Street Tenant",
            "owner_data": "Kane Williamson"
          }
        ],
        "stata_manager_name": " ",
        "stata_manager": null,
        "owner_email": "noname2@email.com"
      },
      "supplier": null,
      "owner_folio": {
        "id": 470,
        "total_money": 100,
        "balance": 50,
        "regular_intervals": "Weekly",
        "next_disburse_date": "2023-10-29",
        "withhold_amount": 100,
        "withold_reason": null,
        "agreement_start": "2023-10-22",
        "gained_reason": null,
        "comment": null,
        "agreement_end": "2024-10-21",
        "owner_access": 1,
        "owner_contact_id": 474,
        "created_at": "2023-10-22T13:26:24.000000Z",
        "updated_at": "2023-10-29T13:10:03.000000Z",
        "money_in": 1000,
        "money_out": 0,
        "uncleared": 0,
        "folio_code": "OWN000470",
        "property_id": 5035,
        "company_id": 1,
        "opening_balance": 100,
        "status": 1,
        "fee_status": null,
        "total_balance": 6640
      },
      "tenant": {
        "id": 435,
        "reference": "William Street Tenant",
        "contact_id": 2738,
        "property_id": 5035,
        "first_name": "William Street",
        "last_name": "Tenant",
        "salutation": null,
        "company_name": null,
        "mobile_phone": "4444535",
        "work_phone": null,
        "home_phone": null,
        "email": "kevinzhao@cliqpack.com",
        "abn": null,
        "notes": null,
        "company_id": 1,
        "user_id": null,
        "created_at": "2023-10-26T18:23:52.000000Z",
        "updated_at": "2023-10-26T18:23:52.000000Z",
        "status": "true"
      },
      "chart_of_account": {
        "id": 2,
        "account_name": "Bond Details",
        "company_id": 1,
        "account_type": null,
        "created_at": "2023-07-17T07:41:18.000000Z",
        "updated_at": "2023-07-17T07:41:18.000000Z",
        "type": "Income",
        "description": null,
        "account_number": 400,
        "tax": 1,
        "hidden": 1
      },
      "tenant_folio": {
        "id": 15,
        "tenant_contact_id": 435,
        "property_id": 5035,
        "deposit": 60,
        "money_in": 60,
        "folio_code": "TEN000435",
        "rent_arrers": null
      }
    }
  ]`)

export const invoiceData = { data: invoice }