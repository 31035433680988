export const REGISTER_USER = "register_user"
export const REGISTER_USER_SUCCESSFUL = "register_user_successfull"
export const REGISTER_OWNER_TENANT = "REGISTER_OWNER_TENANT"
export const REGISTER_USER_FAILED = "register_user_failed"
export const REGISTER_USER_CLEAR = "register_user_clear"
export const COMPANY_LIST = "COMPANY_LIST"
export const COMPANY_ADD = "COMPANY_ADD"
export const COMPANY_ADD_FRESH = "COMPANY_ADD_FRESH"
export const COMPANY_LIST_FRESH = "COMPANY_LIST_FRESH"
export const REGISTERED_USER_LIST = "REGISTERED_USER_LIST"
export const USER_DELETE = "USER_DELETE"
export const USER_DELETE_FRESH = "USER_DELETE_FRESH"
export const USER_UPDATE = "USER_UPDATE"
export const USER_UPDATE_FRESH = "USER_UPDATE_FRESH"
export const USER_INFO = "USER_INFO"
export const USER_INFO_FRESH = "USER_INFO_FRESH"
